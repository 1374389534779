import React, { useEffect, useRef, useState } from "react";
import BotResponse from "./BotResponse";
import PromptInputBar from "./PromptInputBar";

const DemoQuery1 = "What is the best type of real estate investment for me?";
const DemoQuery2 = "How much money do I need to start investing in real estate?";
const DemoQuery3 = "What are the biggest risks involved with  real estate investing?";
const DemoQuery4 = "How can I find good real estate investment deals?";

const IntroSection = ({handleSubmit, inputPrompt, setInputPrompt, setShowMenu, handleCardClick}) => {

  return (
//     <div id="introsection">
//   <h1>
//     Introducing RiVirtual Real Estate Intelligence
//     <BotResponse response=" - Your Ultimate Real Estate AI Assistant" />
//   </h1>
//   <h2>
//     Discover RiVirtual, a revolutionary AI-powered platform designed to provide
//     unparalleled insights and solutions for the real estate industry. Whether
//     you're a seasoned investor, a first-time buyer, or a property enthusiast,
//     RiVirtual is your go-to resource for all things real estate.
//   </h2>
//   Features:
//   <ul>
//     <li>Instant answers to real estate queries</li>
//     <li>Advanced AI technology for precise data analysis</li>
//     <li>Continuous learning to adapt to market trends</li>
//     <li>Intuitive and easy-to-use interface</li>
//     <li>Available 24/7 to cater to your needs</li>
//   </ul>
//   <p>
//     Say goodbye to traditional real estate searches and embrace RiVirtual, your
//     trusted AI assistant in the world of real estate. Experience the future of
//     property intelligence today with RiVirtual.
//   </p>
// </div>
<div className="intro">
<div id="intro-logo" className="text-center">
  <img src="./RiVirtual_logo_intoPage.png" alt="RiVirtualAI"/>
  <a href="https://rivirtual.in/CRM" target="_blank"><span className="badge text-bg-success">Book Demo!</span></a>
</div>
<h1 className="my-3 text-center display-6">RiVirtual AI: Your Comprehensive, AI-Powered<br/>Real Estate Intelligence Platform</h1>
<div className="input-window">
    <PromptInputBar 
      handleSubmit={handleSubmit}
      inputPrompt={inputPrompt}
      setInputPrompt={setInputPrompt}
    />
    <div className="container prompts">
       
        <div className="container text-center">
            <div className="row g-2">
              <div className="col-6">
                <div 
                  className="card"
                  onClick={(e) => handleCardClick(e,DemoQuery1)}
                >
                    <div className="card-body p-2">
                    <p className="card-text">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 0C21.5 0 0 21.5 0 48L0 464c0 26.5 21.5 48 48 48l96 0 0-80c0-26.5 21.5-48 48-48s48 21.5 48 48l0 80 96 0c26.5 0 48-21.5 48-48l0-416c0-26.5-21.5-48-48-48L48 0zM64 240c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32zm112-16l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32zM80 96l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32zM272 96l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16z"/></svg>
                        {DemoQuery1}
                    </p>
                    </div>
                </div>
              </div>
              <div className="col-6">
                <div className="card"
                  onClick={(e) => handleCardClick(e,DemoQuery2)}
                >
                    <div className="card-body p-2">
                    <p className="card-text">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M312 24l0 10.5c6.4 1.2 12.6 2.7 18.2 4.2c12.8 3.4 20.4 16.6 17 29.4s-16.6 20.4-29.4 17c-10.9-2.9-21.1-4.9-30.2-5c-7.3-.1-14.7 1.7-19.4 4.4c-2.1 1.3-3.1 2.4-3.5 3c-.3 .5-.7 1.2-.7 2.8c0 .3 0 .5 0 .6c.2 .2 .9 1.2 3.3 2.6c5.8 3.5 14.4 6.2 27.4 10.1l.9 .3s0 0 0 0c11.1 3.3 25.9 7.8 37.9 15.3c13.7 8.6 26.1 22.9 26.4 44.9c.3 22.5-11.4 38.9-26.7 48.5c-6.7 4.1-13.9 7-21.3 8.8l0 10.6c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-11.4c-9.5-2.3-18.2-5.3-25.6-7.8c-2.1-.7-4.1-1.4-6-2c-12.6-4.2-19.4-17.8-15.2-30.4s17.8-19.4 30.4-15.2c2.6 .9 5 1.7 7.3 2.5c13.6 4.6 23.4 7.9 33.9 8.3c8 .3 15.1-1.6 19.2-4.1c1.9-1.2 2.8-2.2 3.2-2.9c.4-.6 .9-1.8 .8-4.1l0-.2c0-1 0-2.1-4-4.6c-5.7-3.6-14.3-6.4-27.1-10.3l-1.9-.6c-10.8-3.2-25-7.5-36.4-14.4c-13.5-8.1-26.5-22-26.6-44.1c-.1-22.9 12.9-38.6 27.7-47.4c6.4-3.8 13.3-6.4 20.2-8.2L264 24c0-13.3 10.7-24 24-24s24 10.7 24 24zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5L192 512 32 512c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l36.8 0 44.9-36c22.7-18.2 50.9-28 80-28l78.3 0 16 0 64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0-16 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l120.6 0 119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384c0 0 0 0 0 0l-.9 0c.3 0 .6 0 .9 0z"/></svg>
                        {DemoQuery2}
                    </p>
                    </div>
                </div>
              </div>
              <div className="col-6">
                <div className="card"
                  onClick={(e) => handleCardClick(e,DemoQuery3)}
                >
                    <div className="card-body p-2">
                    <p className="card-text">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M160 80c0-26.5 21.5-48 48-48l32 0c26.5 0 48 21.5 48 48l0 352c0 26.5-21.5 48-48 48l-32 0c-26.5 0-48-21.5-48-48l0-352zM0 272c0-26.5 21.5-48 48-48l32 0c26.5 0 48 21.5 48 48l0 160c0 26.5-21.5 48-48 48l-32 0c-26.5 0-48-21.5-48-48L0 272zM368 96l32 0c26.5 0 48 21.5 48 48l0 288c0 26.5-21.5 48-48 48l-32 0c-26.5 0-48-21.5-48-48l0-288c0-26.5 21.5-48 48-48z"/></svg>
                        {DemoQuery3}
                    </p>
                    </div>
                </div>
              </div>
              <div className="col-6">
                <div className="card"
                  onClick={(e) => handleCardClick(e,DemoQuery4)}
                >
                    <div className="card-body p-2">
                    <p className="card-text">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2l144 0c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48l-97.5 0c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3l0-38.3 0-48 0-24.9c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192l64 0c17.7 0 32 14.3 32 32l0 224c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32L0 224c0-17.7 14.3-32 32-32z"/></svg>
                        {DemoQuery4}
                    </p>
                    </div>
                </div>
              </div>
            </div>
          </div>
    </div>
</div>
</div>
  );
};

export default IntroSection;
