import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Avatar from "../components/Avatar";
import BotResponse from "../components/BotResponse";
import Error from "../components/Error";
import IntroSection from "../components/IntroSection";
import Loading from "../components/Loading";
import NavContent from "../components/NavContent";
import SvgComponent from "../components/SvgComponent";
import Markdown from "react-markdown";
import PromptInputBar from "../components/PromptInputBar";

const Home = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [err, setErr] = useState(false);
  const [responseFromAPI, setResponseFromAPI] = useState(false);

  const chatLogEndRef = useRef(null);

  const handleCardClick = function(e, prompt) {
    setInputPrompt(prompt);
    // setShowMenu(true);
    handleSubmit(e,prompt);
  }

  const handleSubmit = async (event, prompt) => {
    const finalPrompt = prompt || inputPrompt;
    event.preventDefault();
    console.log("called------------------",finalPrompt);
    if (!responseFromAPI && finalPrompt.trim() !== "") {
      const newChatLogEntry = { chatPrompt: finalPrompt, botMessage: null };
      setChatLog((prevChatLog) => [...prevChatLog, newChatLogEntry]);

      // hide the keyboard in mobile devices
      //e.target.querySelector("input").blur();

      setInputPrompt(""); // Clear input after submitting
      setResponseFromAPI(true); // Indicate that a response is being awaited

      try {
        //const API_PATH = (process.env.NODE_ENV !== 'production'?'http://localhost:3000' : '') + '/api/v1/rivirtualAI';
        //console.log(`******************${API_PATH}****************`);
        //console.log("I am here!");
        const { data } = await axios.post(`/api/v1/rivirtualAI`, {
          userInput: {
            query: finalPrompt,
            model: 'llama-3.1-sonar-small-128k-online'
          }
        });
        //console.log(data.result.message.content);

        // Update chat log with the new response
        setChatLog((prevChatLog) => [
          ...prevChatLog.slice(0, prevChatLog.length - 1), // all entries except the last
          { ...newChatLogEntry, botMessage: data.result.message.content }, // update the last entry with the bot's response
        ]);

        setErr(false);
      } catch (error) {
        setErr(error);
        console.error(error);
      } finally {
        setResponseFromAPI(false); // Reset after receiving the response
      }
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the chat log to show the latest message
    if (chatLogEndRef.current) {
      chatLogEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [chatLog]);

  return (
    <>
      <header className="fixed-top">
        <div className="menu">
          <button onClick={() => setShowMenu(true)}>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
            >
              <path d="M21 18H3M21 12H3M21 6H3" />
            </svg>
          </button>
        </div>
        <h1>RiVirtual AI</h1> 
        {/* buymeacoffee.png */}
      </header>

      {showMenu && (
        <nav>
          <div className="navItems">
            <NavContent
              chatLog={chatLog}
              setChatLog={setChatLog}
              setShowMenu={setShowMenu}
            />
          </div>
          <div className="navCloseIcon">
            <svg
              fill="#2b6f00"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              xmlSpace="preserve"
              stroke="#2b6f00"
              width={42}
              height={42}
              onClick={() => setShowMenu(false)}
            >
              <path d="m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z" />
            </svg>
          </div>
        </nav>
      )}


      {/* {showMenu && ( */}
        <div className="sideMenu">
          <NavContent
            chatLog={chatLog}
            setChatLog={setChatLog}
            setShowMenu={setShowMenu}
          />
        </div>
      {/* )} */}

      <div className="container">
        <section className="chatBox">
          {chatLog.length > 0 ? (
            <div>
              <div className="chatLogWrapper">
                {chatLog.map((chat, idx) => (
                  <div className="chatLog" key={chat.id} id={`chat-${chat.id}`}>
                    {/* User message */}
                    <div className="chatPromptMainContainer">
                      <div className="chatPromptWrapper">
                        <Avatar bg="#fff" className="userSVG">
                          {/* User avatar */}
                          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#338206" className="bi bi-person" viewBox="0 0 16 16">
  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
</svg>
                        </Avatar>
                        <div id="chatPrompt">{chat.chatPrompt}</div>
                      </div>
                    </div>
                    {/* Bot response */}
                    <div className="botMessageMainContainer">
                      <div className="botMessageWrapper">
                        <Avatar bg="#fff" className="openaiSVG">
                          {/* Bot avatar */}
                          <img src="/RiVirtual_logo_compact.png" alt="Bot Avatar" width={80}/>
                        </Avatar>
                        {chat.botMessage === "Loading..." ? (
                          <Loading />
                        ) : err ? (
                          <Error err={err} />
                        ) : (
                          <div id="botMessage">
                            <Markdown>
                              {/* <pre > */}
                              {chat.botMessage}
                              {/* </pre> */}
                            </Markdown>
                          </div>
                          // <BotResponse response={chat.botMessage} id="botMessage"/>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div ref={chatLogEndRef} />{" "}
                {/* Invisible element to scroll into view */}
              </div>
              <PromptInputBar
                handleSubmit={handleSubmit}
                inputPrompt={inputPrompt}
                setInputPrompt={setInputPrompt}
              />
            </div>
          ) : (
            <IntroSection
              handleSubmit={handleSubmit}
              inputPrompt={inputPrompt}
              setInputPrompt={setInputPrompt}
              setShowMenu={setShowMenu}
              showMenu={showMenu}
              handleCardClick={handleCardClick}
            />
          )}
        </section>
      </div>
    </>
  );
};

export default Home;
