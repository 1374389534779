import React from "react";

const BuyMeACoffeeButton = () => {
  return (
    <div >
      <a
        href="https://rivirtual.in/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/buymeacoffee.png"
          alt="RiVirtual"
          style={{
            height: "50px",
            position: "relative",
            left: "4px",
            display: "block",
          }}
        />
      </a>
    </div>
  );
};

export default BuyMeACoffeeButton;
